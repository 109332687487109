import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";

export const dashboardService = createApi({
  reducerPath: "dashboardApi",
  tagTypes: [
    "Dashboard.Summary",
    "Dashboard.FilesActivity",
    "Dashboard.OutstandingNeedsList",
    "Dashboard.ReviewNeedsList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getCompanySummary: builder.query({
      query: () => "companies/summary",
      providesTags: ["Dashboard.Summary"],
    }),
    getCompanyFilesActivity: builder.query({
      query: () => "companies/needs-list/files-activity",
      providesTags: ["Dashboard.FilesActivity"],
    }),
    getCompanyOutstandingNeedsListToComplete: builder.query({
      query: params => `companies/needs-list/outstanding?${getQueryParams(params)}`,
      providesTags: ["Dashboard.OutstandingNeedsList"],
    }),
    getCompanyOutstandingNeedsListToReview: builder.query({
      query: params => `companies/needs-list/review?${getQueryParams(params)}`,
      providesTags: ["Dashboard.ReviewNeedsList"],
    }),
  }),
});

export const {
  useGetCompanySummaryQuery,
  useGetCompanyFilesActivityQuery,
  useGetCompanyOutstandingNeedsListToCompleteQuery,
  useGetCompanyOutstandingNeedsListToReviewQuery,
} = dashboardService;
