import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import saveAs from "file-saver";
import isEmpty from "lodash/isEmpty";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { showNotification } from "../actions/notification";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { ANALYTICS_USER_TYPES, identifyUser } from "@/utils/analytics";
import { setLenderProfileData } from "@/redux/reducers/lender_profile";

export const lenderSummaryNeedsListService = createApi({
  reducerPath: "lenderSummaryNeedsListApi",
  tagTypes: ["LenderSummaryNeedsList"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoanSummaryNeedsList: builder.query({
      query: ({ token, ...params }) => `lenders/${token}/needs-list?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              // ...result.map(({ needsListFileId }) => ({ type: "LenderSummaryNeedsList", needsListFileId })),
              { type: "LenderSummaryNeedsList", id: "LIST" },
            ]
          : [{ type: "LenderSummaryNeedsList", id: "LIST" }],
      transformResponse: ({ lender, files }) => {
        const treeViewFiles = files.reduce((item, file) => {
          const records = { ...item };
          if (!item[file.borrowerId]) {
            records[file.borrowerId] = {
              borrowerName: file.borrowerName,
              documents: {
                [file.documentId]: {
                  documentName: file.documentName,
                  files: [],
                },
              },
            };
          }

          if (!records[file.borrowerId].documents[file.documentId]) {
            records[file.borrowerId].documents[file.documentId] = {
              documentName: file.documentName,
              files: [],
            };
          }

          records[file.borrowerId].documents[file.documentId].files.push({
            loanId: file.loanId,
            loanSummaryId: file.loanSummaryId,
            borrowerId: file.borrowerId,
            needsListId: file.needsListId,
            needsListFileId: file.needsListFileId,
            needsListFileName: file.needsListFileName,
            isActive: file.isActive,
          });

          return records;
        }, {});

        return { lender, files: treeViewFiles };
      },
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          const { data } = getState().lenderProfileStore;

          identifyUser({
            userId: response.data.lender.lenderId,
            userType: ANALYTICS_USER_TYPES.LENDER,
            email: response.data.lender.lenderEmail,
            firstName: response.data.lender.lenderFirstName,
            lastName: response.data.lender.lenderLastName,
            fullName: `${response.data.lender.lenderFirstName} ${response.lenderLastName}`,
            companyId: response.data.lender.companyId,
            companyName: response.data.lender.companyName,
          });

          if (isEmpty(data)) dispatch(setLenderProfileData({ firstName: response.data.lender.lenderFirstName }));
        } catch (err) {
          /* empty */
        }
      },
    }),
    downloadLoanSummaryNeedsListPackage: builder.mutation({
      queryFn: async (token, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `lenders/${token}/needs-list/package`,
          responseHandler: response => response.blob(),
        });

        saveAs(result.data, "package.zip");

        api.dispatch(
          showNotification({
            ...DEFAULT_NOTIFICATION_PAYLOAD,
            message: {
              ...DEFAULT_NOTIFICATION_PAYLOAD.message,
              description: "Package generated successfully! The file has been automatically saved to your device.",
              options: {
                variant: NOTIFICATION_VARIANTS.SUCCESS,
              },
            },
          }),
        );

        return { data: result.data };
      },
    }),
  }),
});

export const { useGetLoanSummaryNeedsListQuery, useDownloadLoanSummaryNeedsListPackageMutation } =
  lenderSummaryNeedsListService;
